// This element gets injected with JS dependent on the JS variable carouselBackgroundVideoYouTubeID
// It's designed to add a background autoplay YouTube video to the first slide on the homepage carousel

@if $carousel-yt-background-enabled == true {
  @if $warn == true {
    @warn "Don't forget to set the Javascript variable \"var carouselBackgroundVideoYouTubeID\" to the ID of your YouTube video (e.g. 'dQw4w9WgXcQ')";
  }

  .yt-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    pointer-events: none;

    @if $carousel-yt-background-size == 'cover' {
      height: 0;
      padding-bottom: $carousel-yt-cover-padding-bottom; // https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
    } @else {
      height: 100%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}
